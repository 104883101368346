import axios from "@axios";

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchKarmaCatalogue(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/karma-catalogue", { params: queryParams })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
        fetchKarmaCatalogStatus() {
            return new Promise((resolve, reject) => {
                axios
                    .get("/list/karma-catalogue-status")
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
        fetchKarmaCatalog(ctx, id) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`karma-catalogue/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        addKarmaCatalog(ctx, userData) {
            return new Promise((resolve, reject) => {
                axios
                    .post("karma-catalogue", userData, {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        console.log(error);
                        if (error.response.status === 422) {
                            if (
                                typeof error.response.data.errors !==
                                "undefined"
                            ) {
                                const keys = Object.keys(
                                    error.response.data.errors
                                );
                                const values = Object.values(
                                    error.response.data.errors
                                );
                                for (let i = 0; i <= keys.length; i += 1) {
                                    if (
                                        error.response.data.errors.hasOwnProperty.call(
                                            values,
                                            i
                                        )
                                    ) {
                                        reject(new Error(values[0][0]));
                                    }
                                }
                            }
                        } else {
                            reject(error);
                        }
                    });
            });
        },
        updateKarmaCatalog(ctx, karma_catalog) {
            return new Promise((resolve, reject) => {
                axios
                    .patch(`karma-catalogue/${karma_catalog.id}`, karma_catalog.data, {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            if (
                                typeof error.response.data.errors !==
                                "undefined"
                            ) {
                                const keys = Object.keys(
                                    error.response.data.errors
                                );
                                const values = Object.values(
                                    error.response.data.errors
                                );
                                for (let i = 0; i <= keys.length; i += 1) {
                                    if (
                                        error.response.data.errors.hasOwnProperty.call(
                                            values,
                                            i
                                        )
                                    ) {
                                        reject(new Error(values[0][0]));
                                    }
                                }
                            }
                        } else {
                            reject(error);
                        }
                    });
            });
        },
        removeKarmaCatalog(ctx, userId) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`karma-catalogue/${userId}`)
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        removeMultipleKarmaCatalogue(ctx, ids) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/karma-catalogue/destroy/`, { ids: ids })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        getCustomFields() {
            return new Promise((resolve, reject) => {
                axios
                    .get("/karma-catalogue/create")
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
    }
};
